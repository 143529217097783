// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_5274 #slider {
  height: 100vh;
  max-height: 1080px;
  min-height: 550px;
  position: relative;

  @media only screen and (max-width: 990px) {
    height: 800px;
    min-height: 800px;
  }

  &:after {
    @include before(100%, 300px);
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba($slider-grad-col, 1) 0%, rgba($slider-grad-col, 0) 100%);
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  min-height: 600px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 990px) {
    height: 500px;
    min-height: 500px;
  }
}

.backstretch {
  position: relative;

  img {
    top: 0px !important;
    animation: scale 25s ease forwards infinite;
  }

  @keyframes scale {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.25);
    }
  }
}

.body_5274 .backstretch {

  @media only screen and (min-width: 990px) {
    opacity: 0;
    animation: appear 2s ease forwards 1;

    @keyframes appear {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }
}


// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  right: 10%;
  bottom: 50px;
  margin: 0;
  // width: 990px;
  max-width: 95%;
  text-align: right;
  z-index: 99;
  color: #fff;
  line-height: 1;
  font-size: 50px;
  font-weight: bold;

  @media only screen and (max-width: 1800px) {
    font-size: 45px;
  }

  @media only screen and (max-width: 1500px) {
    font-size: 40px;
  }

  @media only screen and (max-width: 1350px) {
    font-size: 35px;
  }

  @media only screen and (max-width: 990px) {
    // @include posreset;
    font-size: 30px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 25px;
  }
}