	// -----------------------------------//
	// FOOTER
	// -----------------------------------//

	footer {
		clear: both;
		margin: 0 auto;
		padding: 40px 0 20px;
		background: $footer-bg;
		position: relative;
		z-index: 99;
		border-top: 5px solid $footer-border-top;
		color: $footer-text-col;

		@media only screen and (max-width: 990px) {
			height: auto;
			padding: 30px 0;
		}

		.wrapper {
			text-align: left;
			font-size: 18px;
			max-width: 95%;

			@media only screen and (max-width: 990px) {
				max-width: unset;
				width: 100%;
				padding: 0 5%;
				text-align: center;
			}
		}

		.trust-cont,
		.details-cont,
		.social-cont {
			display: inline-block;
			vertical-align: top;

			@media only screen and (max-width: 990px) {
				display: block;
				text-align: center;
			}

			h2 {
				text-transform: uppercase;
				margin: 0 0 15px 0;
				line-height: 1;
				font-weight: bold;

				@media only screen and (max-width: 990px) {
					text-align: center;
					margin: 0 auto 20px;
				}
			}
		}

		.trust-cont {
			width: 30%;

			@media only screen and (max-width: 990px) {
				width: auto;
				margin: 0 auto 50px;

				img {
					display: inline-block;
					margin: 0 auto;
				}
			}

			p {
				margin: 0 0 10px 0;

				@media only screen and (max-width: 990px) {
					text-align: center;
					margin: 0 auto;
					font-size: 13px;
				}
			}
		}

		.details-cont {
			width: 35%;
			margin: 0 0 0 50px;

			@media only screen and (max-width: 990px) {
				width: auto;
				margin: 0 auto 50px;
			}

			p {
				margin: 0 0 15px 0;

				@media only screen and (max-width: 990px) {
					margin: 0 auto 10px;
				}
			}

			a[href^="mailto:"] {
				display: inline-block;
				position: relative;
				color: $footer-text-col;

				@media only screen and (max-width: 990px) {
					word-break: break-all;
					text-align: center;
				}

				&:before {
					@include before(0, 4px);
					border-radius: 10px;
					background: $footer-text-col;
					transition: .3s;
					left: 0;
					bottom: -5px;
				}

				@media only screen and (min-width: 990px) {

					&:hover {
						&:before {
							width: 100%;
						}
					}
				}
			}
		}

		.social-cont {
			width: calc(37% - 100px);

			@media only screen and (max-width: 990px) {
				width: auto;
				margin: 0 auto;
			}

			ul {
				text-align: left;

				@media only screen and (max-width: 990px) {
					text-align: center;
				}
			}

			li {
				display: inline-block;
				vertical-align: top;
				padding: 0;
				background: none;
				margin: 0 4px 25px 0;
				position: relative;

				@media only screen and (max-width: 990px) {
					display: inline-block;
					margin: 5px;
				}

				img {
					display: inline-block;
					margin: 0 auto;
					vertical-align: middle;
				}

				.tooltip {
					position: absolute;
					left: 50%;
					margin: 0;
					bottom: -10px;
					width: 100px;
					text-align: center;
					transform: translateX(-50%);
					color: $footer-text-col;
					font-size: 12px;
					pointer-events: none;
					user-select: none;
					transition: .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
					opacity: 0;
					z-index: -1;

					@media only screen and (max-width: 990px) {
						display: none;
					}
				}

				&:hover {
					.tooltip {
						opacity: 1;
						bottom: -22px;
					}
				}
			}
		}
	}

	#map {
		height: 250px;
		overflow: hidden;
	}

	// -----------------------------------//
	// SUB-FOOTER
	// -----------------------------------//

	.sub-footer {
		padding: 10px 0;
		overflow: hidden;
		@extend .clearfix;
		text-align: left;
		background: $footer-bg;
		color: $footer-text-col;
		text-align: right;

		@media only screen and (max-width: 990px) {
			height: auto;
			line-height: normal;
			padding: 5px 0;
		}

		.wrapper {
			width: 90%;
		}

		p {
			font-size: 13px;
			margin: 0;

			@media only screen and (max-width: 990px) {
				text-align: center;
				margin: 5px auto;
			}
		}

		#copyright,
		#credit {
			display: inline-block;
			margin: 0;
			font-size: 13px;

			@media only screen and (max-width: 990px) {
				text-align: center;
				display: block;
				margin: 5px auto;
			}

			a {
				color: $footer-text-col;

				@media only screen and (max-width: 990px) {
					margin: 0;
					display: inline-block;
				}

				&:last-of-type {
					color: $footer-link-col;
				}
			}
		}

		#copyright {
			margin: 0 30px 0 0;

			@media only screen and (max-width: 990px) {
				margin: 30px auto 5px;
				display: block;
			}
		}
	}

	// -----------------------------------//
	// TOP BUTTON
	// -----------------------------------//

	.top-btn {
		position: fixed;
		z-index: 99999;
		bottom: 80px;
		right: 50px;
		display: block;
		@include box(45px);
		background: $h1-color;
		box-shadow: 0 0 0 3px #fff, 0 0 0 8px rgba(#fff, .2);
		border-radius: 50%;
		color: #fff;
		font-size: 25px;
		text-align: center;
		opacity: 0;
		transform: scale(0);
		transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

		@media only screen and (max-width: 1199px) {
			display: none;
		}
	}

	.show-top-btn {
		opacity: 1;
		transform: scale(1);
	}

	// -----------------------------------//
	// AWARDS
	// -----------------------------------//

	#awards {
		width: 100%;
		overflow: hidden;
		background: #fff;
		padding: 20px 0;

		.CMSawards {
			overflow: hidden;
			width: 100%;
			height: 100%;
			margin: 0 auto !important;
			background: none !important;

			ul {
				margin: 0;

				li {
					background: none;
					padding: 0 5px;
					margin: 0 10px;
					display: inline-block;

					img {
						max-height: 70px;
						transform: none !important;
					}
				}
			}
		}

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}