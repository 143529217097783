// --------------------------------------------------------------------- //
// EDIT COLOURS IN SVG FILES IN /I/DESIGN... ETC TO AVOID PS EXPORTING
// --------------------------------------------------------------------- //

$booya-color: #1b6533; // booya because this is everywhere (primary)

// NAV

$nav-col: #222;
$nav-hov-same-as-active-tab: $booya-color;

$header-bg: #fff;

// LOGO

$logo-text-col: #222;

// GRADIENT ON SLIDER

$slider-grad-col: #111;

// QL

$ql-panel-col: lighten($booya-color, 10%);

// WELCOME STRIP THINGY

$strip1: $booya-color;
$strip2: darken($booya-color, 15%);
$strip3: lighten($booya-color, 30%);
$strip4: lighten($booya-color, 10%);
$strip5: darken($booya-color, 10%);
$strip6: $booya-color;
$strip7: lighten($booya-color, 50%);

// WELCOME

$welcome-bg: #fff;
$welcome-slice: $welcome-bg;
$welcome-h2-col: $booya-color;
$welcome-p-col: #222;

// STATS

$stats-sec-bg: $booya-color;
$stats-sec-h2-col: #fff;
$stats-digit-col: lighten($booya-color, 50%);
$stats-desc-col: #fff;

// FOOTER

$footer-border-top: #fff;
$footer-bg: lighten($booya-color, 10%);
$footer-text-col: #fff;
$footer-link-col: lighten($booya-color, 50%);