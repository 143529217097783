// -----------------------------------//
// ELEMENTS COLOURS
// -----------------------------------//

$slideOut-panel-bg: $ql-panel-col;
$mobile-tab-color: $ql-panel-col;
$click-box: $ql-panel-col;

/* Full container */
#slideOut {
	position: fixed;
	width: auto;
	height: auto;
	min-height: 250px;
	top: 50%;
	transform: translateY(-50%);
	margin: 0 !important;
	left: -250px;
	padding: 0;
	z-index: 999;
	transition: top .3s;
}

#slideOut.up {
	top: 80px;
	transform: none;

	#slideClick span {
		text-shadow: 1px 1px 5px rgba(0, 0, 0, .3);
	}
}

/* Click to slide */
#slideClick {
	position: relative;
	height: 100%;
	width: 80px;
	float: right;
	cursor: pointer;

	span {
		padding: 20px 60px;
		@include center;
		transform: translate(-45%, -50%) rotate(-90deg);
		white-space: nowrap;
		font-size: 16px;
		color: #fff;
		background: $click-box;
		text-transform: uppercase;
		font-weight: bold;
	}

	// -------------------- toggle arrow

	// &:after {
	// 	@include before(8px, 13px);
	// 	background: url(/i/design/ql-ic.png) no-repeat center;
	// 	right: 0;
	// 	top: 50%;
	// 	transform: translateY(-50%);
	// 	visibility: visible;
	// 	display: block;
	// }
}

// #slideClick.flippah {
// 	&:after {
// 		transform: translateY(-50%) scaleX(-1);
// 	}
// }

// $(document).ready(function () {
// 	$('#slideClick').click(function () {
// 		$(this).toggleClass('flippah');
// 	});
// });

.flip {
	display: none;
}

/* Content links */
#slideContent {
	width: auto;
	height: 100%;
	padding: 0;
	margin: 0;
	float: left;
	color: #EFEFEF;
	text-align: center;
}

/* Box with links  */
ul.quick-links {
	width: 240px;
	display: block;
	text-align: center;
	overflow: hidden;
	background: $click-box;

	li {
		background: none;
		margin: 0;
		padding: 0;
		display: block;

		a {
			color: #fff;
			display: block;
			text-align: left;
			transition: .3s;
			font-size: 16px;
			padding: 8px 10px 8px 25px;

			&:hover {
				transition: .3s;
				background: lighten($click-box, 10%);
			}
		}
	}
}

@media only screen and (min-width: 990px) {

	#slideContent {
		display: table !important;
	}

	ul.quick-links {
		vertical-align: middle;
		display: table-cell;
	}
}

@media only screen and (max-width: 990px) {

	#slideOut {
		position: absolute;
		width: 100%;
		top: 44px;
		min-height: 0px;
		left: 0;
		padding: 0px 3% 0 2%;
		overflow: visible;
		height: auto !important;
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
		border-radius: 0;
		margin: 0 !important;
		background: $mobile-tab-color;
		transform: none;
	}

	#slideClick {
		display: none;

		span {
			transform: rotate(0);
			position: absolute;
			top: 0;
			left: 0;
			width: auto;
			height: auto;
			margin-left: 0;
			margin-top: 0;
			position: relative;
		}
	}

	#slideContent {
		float: none;
	}

	ul.quick-links {
		width: 100%;
		transform: none;

		li {
			a {
				padding: 6px 0 6px 0;
				text-align: center;
				background-position: 8% 10px;
				font-size: 15px;
			}
		}
	}

	.flip {
		z-index: 800;
		margin: 0;
		padding: 5px 0;
		cursor: pointer;
		font-size: 13px;
		color: #fff;
		display: block;

		img {
			display: block;
			margin: 0 auto;
			transform: rotate(90deg);
		}
	}

	.open img {
		transform: rotate(-90deg);
	}
}