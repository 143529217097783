// ----------------------------------- WELCOME SEC

#welcome-sec {
 padding: 100px 0 150px;
 background: $welcome-bg;
 position: relative;

 @media only screen and (max-width: 1800px) {
  padding: 90px 0 140px;
 }

 @media only screen and (max-width: 1500px) {
  padding: 80px 0 130px;
 }

 @media only screen and (max-width: 1350px) {
  padding: 70px 0 120px;
 }

 @media only screen and (max-width: 990px) {
  padding: 60px 0;
 }

 &:before {
  @include before(564px, 100%);
  background: url(/i/design/welcome-img.jpg) no-repeat top right;
  top: 0;
  left: 0;
  background-size: cover;
  mix-blend-mode: luminosity;
  opacity: .7;

  @media only screen and (max-width: 990px) {
   display: none;
  }
 }

 &:after {
  @include before(400px, 100%);
  background: $welcome-slice;
  top: 0;
  left: 0;
  left: 164px;
  clip-path: polygon(50% 0, 100% 0%, 100% 100%, 90% 100%);

  @media only screen and (max-width: 990px) {
   display: none;
  }
 }

 .stripper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 8px;

  span {
   height: 100%;
   width: calc(100% / 7);
   float: left;

   &:nth-of-type(1) {
    background: $strip1;
   }

   &:nth-of-type(2) {
    background: $strip2;
   }

   &:nth-of-type(3) {
    background: $strip3;
   }

   &:nth-of-type(4) {
    background: $strip4;
   }

   &:nth-of-type(5) {
    background: $strip5;
   }

   &:nth-of-type(6) {
    background: $strip6;
   }

   &:nth-of-type(7) {
    background: $strip7;
   }
  }
 }

 .wrapper {
  width: 100%;
  padding: 0 8% 0 12.9%;
  position: relative;
  z-index: 99;

  @media only screen and (max-width: 1800px) {
   padding: 0 5% 0 10%;
  }

  @media only screen and (max-width: 1500px) {
   padding: 0 5% 0 8%;
  }

  @media only screen and (max-width: 1350px) {
   padding: 0 5%;
  }
 }

 .img-cont,
 .text-cont {
  display: inline-block;
  vertical-align: top;
  position: relative;
  z-index: 1;
 }

 .img-cont {
  width: 500px;
  margin: 0 50px 0 0;
  position: relative;
  transform: translateY(-30px);

  @media only screen and (max-width: 1500px) {
   transform: scale(.9);
  }

  @media only screen and (max-width: 1350px) {
   transform: scale(.7);
   margin: 0;
  }

  @media only screen and (max-width: 990px) {
   width: 284px;
   display: block;
   transform: none;
   margin: 0 auto 30px;
  }

  img.round-img {
   @include box(284px);
   border-radius: 50%;
   transform: translateX(230px);
   object-fit: cover;

   @media only screen and (max-width: 990px) {
    transform: none;
   }
  }

  img:not(.round-img) {
   position: absolute;

   @media only screen and (max-width: 990px) {
    display: none;
   }
  }

  .img-top {
   left: 0;
   top: 200px;
  }

  .img-bottom {
   left: 185px;
   top: 295px;
   transform: rotate(1deg);
  }
 }

 .text-cont {
  width: calc(100% - 500px - 80px);

  @media only screen and (max-width: 990px) {
   width: 100%;
   display: block;
   margin: 0 auto;
  }

  h2 {
   margin: 0 0 80px 0;
   text-transform: uppercase;
   font-size: 2vw;
   line-height: 1.2;
   color: $welcome-h2-col;
   font-weight: bold;
   letter-spacing: -0.05em;

   @media only screen and (max-width: 1500px) {
    margin: 0 0 50px 0;
   }

   @media only screen and (max-width: 1350px) {
    font-size: 25px;
    margin: 0 0 30px 0;
   }

   span {
    display: block;
   }
  }

  p {
   margin: 0 0 20px 0;
   padding: 0 0 0 50px;
   color: $welcome-p-col;
   font-size: 20px;

   @media only screen and (max-width: 1800px) {
    font-size: 18px;
   }

   @media only screen and (max-width: 1500px) {
    font-size: 16px;
   }

   @media only screen and (max-width: 1350px) {
    padding: 0;
   }
  }
 }
}

#stats-sec {
 padding: 100px 0 120px;
 text-align: center;
 background: $stats-sec-bg;
 position: relative;
 overflow: hidden;

 @media only screen and (max-width: 1800px) {
  padding: 90px 0 110px;
 }

 @media only screen and (max-width: 1500px) {
  padding: 80px 0 100px;
 }

 @media only screen and (max-width: 1350px) {
  padding: 70px 0 90px;
 }

 @media only screen and (max-width: 990px) {
  padding: 60px 0;
 }

 &:before {
  @include before(100%, 100%);
  top: 0;
  left: 0;
  background: url(/i/design/stats-bg.png) no-repeat top left;
  filter: brightness(2000%);
 }

 h2 {
  text-align: center;
  color: $stats-sec-h2-col;
  margin: 0 auto 80px;
  line-height: 1.2;
  font-size: 34px;

  @media only screen and (max-width: 1500px) {
   margin: 0 auto 50px;
  }

  @media only screen and (max-width: 1350px) {
   font-size: 25px;
   margin: 0 auto 30px;
  }

  span {
   display: block;
   text-transform: uppercase;
   font-weight: bold;
  }
 }

 ul {
  margin: 0 auto;

  li {
   display: inline-block;
   vertical-align: middle;
   width: 30%;
   margin: 0 1%;

   @media only screen and (max-width: 990px) {
    display: block;
    text-align: center;
    margin: 0 auto 20px;
    width: auto;
   }

   p {
    font-size: 6vw;
    margin: 0 auto;
    color: $stats-digit-col;
    font-weight: bold;

    @media only screen and (max-width: 990px) {
     font-size: 70px;
    }
   }

   span {
    color: $stats-desc-col;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 1;

    @media only screen and (max-width: 1500px) {
     font-size: 25px;
    }

    @media only screen and (max-width: 1350px) {
     font-size: 22px;
    }

    @media only screen and (max-width: 990px) {
     font-size: 20px;
    }
   }
  }
 }
}